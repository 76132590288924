<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Credit Card</label>
      <div class="col-3">
        <Autocomplete :items="creditCardList" input-class="form-control" @autocompleteSelected="selectedItem" :init-value="form.creditCard"></Autocomplete>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Type</label>
      <div class="col-2">
          <select v-model="form.type" class="form-select">
            <option v-for="(item, index) in typeList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Amount</label>
      <div class="col-3">
            <input type="text" v-model="form.amount" class="form-control"/>
      </div>
    </div>
    <div v-if="form.type === 'Bill Payment'" class="mb-3 row">
      <label class="col-1 col-form-label">Request Date</label>
      <div class="col-3">
        <datepicker v-model="form.requestDate" typeable :clearable="true" class="form-control" style="width: 280px"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Due Date</label>
      <div class="col-3">
        <datepicker v-model="form.dueDate" typeable :clearable="true" class="form-control" style="width: 280px" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Source</label>
      <div v-if="form.type === 'Bill'" class="col-3">
          <select v-model="form.source" class="form-select">
            <option v-for="(item, index) in sourceList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
      <div v-else class="col-3">
            <input type="text" v-model="form.source" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
          <select v-model="form.status" class="form-select">
            <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { decode, encode } from "../util.js"

export default {
  name: "CreditCardBillEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || "",
        creditCard: this.$route.query.creditcard || "",
        type: "Bill Payment",
        status: "Not Paid",
        source: ""
      },
      action: this.$route.query.action || "",
      breadcrumbs: [{ name: "Credit Card", link: "/creditcard" }, { name: "Bill", link: "/creditcard/bill" }, { name: (this.$route.query.id ? "Update" : "New") + " Bill " + (this.$route.query.action || "") }],
      alertContent: "",
      alertClass: "alert-danger",
      sourceList:  ["American Express", "Citibank", "Chase", "US Bank", "Fidelity", "Amazon"],
      typeList:  ["Bill Payment", "Bill"],
      statusList: ["Not Paid", "Paid"],
      creditCardList: []
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
      this.selectedItem(this.form.creditCard + " ");
      this.getCreditCardList();
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      if (this.action && this.action === "COPY") {
        this.form.id = "";
      }
      this.form.creditCard = encode(this.form.creditCard);
      if (this.form.amount) {
        this.form.amount = ("" + this.form.amount).replaceAll(",", "");
      }
      axios.post("/creditcard/bill", this.form)
        .then(() => {
          this.submitted = false;
          this.alertClass = "alert-success";
          this.alertContent = "Bill has been saved successfully!";
        })
        .catch((error) => {
          this.alertClass = "alert-danger";
          this.alertContent = error.response ? error.response.data : error;
          this.submitted = false;
        });
    },
    getObject() {
      axios
      .get("/creditcard/bill/" + this.form.id)
      .then((response) => {
        this.form = response.data;
        this.form.creditCard = decode(this.form.creditCard);
        this.form.dueDate = this.form.dueDate ? new Date(this.form.dueDate + "T00:00:00") : new Date();
        this.form.requestDate = this.form.requestDate ? new Date(this.form.requestDate + "T00:00:00") : new Date();
      })
      .catch((error) => {
        this.alertClass = "alert-danger";
        this.alertContent = error.response ? error.response.data : error;
      });
    },
    getCreditCardList() {
      axios
      .get("/creditcard")
      .then((response) => {
        this.creditCardList = response.data.map(item => decode(item.creditcard));
      })
      .catch((error) => {
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
    },
    selectedItem(item) {
      this.form.creditCard = item;
    }
  },
};
</script>

<style>
.v3dp__clearable {
  position: absolute;
  top: 8px;
  left: 260px;
}
</style>